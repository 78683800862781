<template>
  <!-- 发货弹窗 -->
  <el-dialog :title="title" width="400px" :visible="isShowDelivery" center :before-close="cancel">
      <div class="delivery-dialog__item">
        <div class="delivery__item-label"><span class="star_red">* </span>物流公司：</div>
        <el-select v-model="express_com" placeholder="请选择物流公司">
          <el-option v-for="item in sendExpress" :key="item.id" :label="item.delivery_name" :value="item.delivery_id"> </el-option>
        </el-select>
      </div>
      <div class="delivery-dialog__item">
        <div class="delivery__item-label"><span class="star_red">* </span>物流单号：</div>
        <el-input v-model="express_no" class="delivery__item-input" placeholder="请输入物流单号"></el-input>
      </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { fetchExpressList } from "@/api/order";

export default {
  name: 'DeliveryDialog',
  props: {
    isShowDelivery: {
      type: Boolean,
      required: true,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      title: '发货',
      express_com: '',
      sendExpress: [], // 发货信息
      express_no: '', // 物流单号,
    }
  },
  mounted() {
    this.getExpressList()
  },
  methods: {
    // 获取支持的物流公司
    getExpressList() {
      fetchExpressList().then(res => {
        this.sendExpress = res.data
      })
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    },
    confirm() {
      if (!this.express_com) {
        this.$message.warning('请选择物流信息')
        return
      }
      if (!this.express_no) {
        this.$message.warning('请填写物流单号')
        return
      }

      let obj = {
        express_com: this.express_com,
        express_no: this.express_no,
      }
      this.$emit('onConfirm', obj)
    },
    reset() {
      this.express_com = ''
      this.express_no = ''
    },
  },
}
</script>

<style lang="scss" scoped>
// 选择物流弹窗
.delivery-dialog__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .delivery__item-label {
    width: 80px;
    margin-right: 10px;
    .star_red {
      color: #ff0000;
    }
  }
  ::v-deep .el-input {
    width: 260px;
  }
}
</style>
