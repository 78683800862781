import fetch from '@/utils/fetch'

/**
 * 查询订单列表
 */
export function fetchOrderList(params) {
  return fetch({
    url: '/admin/deal/list',
    method: 'GET',
    params,
  })
}

/**
 * 查询订单详情
 */
export function fetchOrderDetail(params) {
  return fetch({
    url: '/admin/deal/detail',
    method: 'GET',
    params,
  })
}

/**
 * 查询供应商订单列表
 */
export function fetchSupplierOrderList(params) {
  return fetch({
    url: '/admin/supplier/deals',
    method: 'GET',
    params,
  })
}

/**
 * 查询快递公司列表
 */
export function fetchExpressList(params) {
  return fetch({
    url: '/admin/deal/express_code',
    method: 'GET',
    params,
  })
}

/**
 * 快递
 */
export function fetchParcel(data) {
  return fetch({
    url: '/admin/deal/edit_parcel',
    method: 'POST',
    data,
  })
}

/**
 * 发货
 */
export function fetchDeliver(data) {
  return fetch({
    url: '/admin/deal/deliver',
    method: 'POST',
    data,
  })
}

/**
 * 退货
 */
export function fetchAfterSaleReturn(data) {
  return fetch({
    url: '/admin/aftersale/return',
    method: 'POST',
    data,
  })
}

/**
 * 完成售后
 */
export function fetchAfterSale(data) {
  return fetch({
    url: '/admin/aftersale/complete',
    method: 'POST',
    data,
  })
}
