<template>
  <div class="page">
    <div class="panel">
      <div class="panel-item">
        <div class="title">待发货</div>
        <div class="num">{{wait_send}}</div>
      </div>
      <div class="panel-item">
        <div class="title">待售后</div>
        <div class="num">{{wait_aftersale}}</div>
      </div>
    </div>
    <div class="chart-section">
      <div class="title">用户增长统计</div>
      <Echart :options="userStaticOption" style="width: 100%; height: 400px" />
    </div>
    <div class="chart-section">
      <div class="title">订单增长统计</div>
      <Echart :options="orderStaticOption" style="width: 100%; height: 400px" />
    </div>
    <div class="chart-section">
      <div class="title">订单金额统计</div>
      <Echart :options="amountStaticOption" style="width: 100%; height: 400px" />
    </div>
  </div>
</template>

<script>
import Echart from '@/components/echartComponent/index.vue'
import * as echarts from 'echarts/dist/echarts'
import {fetchUserStatic, fetchOrderStatic} from '@/api/statics'
export default {
  name: 'Home',
  components: {
    Echart
  },
  data() {
    return {
      userStaticOption: {},
      orderStaticOption: {},
      amountStaticOption: {},
      wait_send: 0,
      wait_aftersale: 0
    }
  },
  created() {
    this.fetchUserStatic()
    this.fetchOrderStatic()
  },
  methods: {
    fetchUserStatic() {
      fetchUserStatic().then(res => {
        const data = res.data || {}
        const xData = Object.keys(data)
        const yData = Object.values(data)
        const options = {
          tooltip: {
            backgroundColor: 'rgba(25, 197, 94, 1)',
            textStyle: {
              fontSize: '24px',
              color: '#fff',
            },
            formatter(params) {
              const { data, name } = params
              return `${name}： 新增用户 <br /> <div style='color:#fff;font-size:30px;text-align:right'>${data}</div>`
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xData,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: yData,
              type: 'line',
              color: '#19C55E',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(25, 197, 94, .3)',
                      },
                      {
                        offset: 0.8,
                        color: 'rgba(25, 197, 94, 0)',
                      },
                    ],
                    false
                  ),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10,
                },
              },
              smooth: true,
            },
          ],
        }
        this.userStaticOption = options
      })
    },
    fetchOrderStatic() {
      fetchOrderStatic().then(res => {
        this.getOrderStaticOption(res.data.order || {})
        this.getAmountStaticOption(res.data.amount)
        this.wait_aftersale = res.data.wait_aftersale || 0
        this.wait_send = res.data.wait_send || 0
      })
    },
    getOrderStaticOption(data) {
        const xData = Object.keys(data)
        const yData = Object.values(data)
        console.log(yData, 9)
        const options = {
          tooltip: {
            backgroundColor: 'rgba(25, 197, 94, 1)',
            textStyle: {
              fontSize: '24px',
              color: '#fff',
            },
            formatter(params) {
              const { data, name } = params
              return `${name}： 订单数 <br /> <div style='color:#fff;font-size:30px;text-align:right'>${data}</div>`
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xData,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: yData,
              type: 'line',
              color: '#19C55E',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(25, 197, 94, .3)',
                      },
                      {
                        offset: 0.8,
                        color: 'rgba(25, 197, 94, 0)',
                      },
                    ],
                    false
                  ),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10,
                },
              },
              smooth: true,
            },
          ],
        }
        this.orderStaticOption = options
    },
    getAmountStaticOption(data) {
        const xData = Object.keys(data)
        const yData = Object.values(data)
        console.log(yData, 9)
        const options = {
          tooltip: {
            backgroundColor: 'rgba(25, 197, 94, 1)',
            textStyle: {
              fontSize: '24px',
              color: '#fff',
            },
            formatter(params) {
              const { data, name } = params
              return `${name}： 订单金额 <br /> <div style='color:#fff;font-size:30px;text-align:right'>${data}</div>`
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xData,
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              data: yData,
              type: 'line',
              color: '#19C55E',
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: 'rgba(25, 197, 94, .3)',
                      },
                      {
                        offset: 0.8,
                        color: 'rgba(25, 197, 94, 0)',
                      },
                    ],
                    false
                  ),
                  shadowColor: 'rgba(0, 0, 0, 0.1)',
                  shadowBlur: 10,
                },
              },
              smooth: true,
            },
          ],
        }
        this.amountStaticOption = options
    },
  }
}
</script>


<style lang="scss" scoped>

.page {
  background: #fff;
  padding: 20px;
}

.panel {
  display: flex;
  padding: 20px 40px;
  justify-content: space-between;
  .panel-item {
    width: 45%;
    padding: 12px 16px;
    padding-right: 5px;
    background: linear-gradient(296deg, #f3f4f9 3%, #f8f9fa);
    border-radius: 8px;
    cursor: pointer;

    .title {
      margin-bottom: 10px;
      color: #333;
      font-size: 16px;
    }

    .num {
      margin-bottom: 10px;
      color: #1a1a1a;
      font-size: 26px;
      font-family: Roboto;
    }
  }
}
.chart-section {

  .title {
    padding: 20px 100px;
    font-size: 18px;
    width: 100%;
    font-weight: 500;
    margin-bottom: 8px;
    height: 30px;
    line-height: 30px;
  }
}
</style>
