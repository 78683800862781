import axios from 'axios'
import { Message } from 'element-ui'

import { baseURL, env } from '@/utils/config'

let messageInstance = null

const showErrorMessage = message => {
  if (messageInstance === null) {
    messageInstance = true
    Message({
      type: 'error',
      message,
      onClose() {
        messageInstance = null
      },
    })
  }
}

const service = axios.create({
  timeout: 30000,
  responseType: 'json',
  withCredentials: true,
})

service.interceptors.request.use(
  config => {
    config.baseURL = config.baseURL || `${baseURL}/`
    config.headers = {
      'token': window.Cookies.get('token') || '',
      'Access-Control-Allow-Origin': '*',
    }
    if (process.env.NODE_ENV === 'development') {
      config.baseURL = config.baseURL.replace(/(^\/api\/)/, `$1${env}/`)
    }
    return config
  },
  error => {
    showErrorMessage('网络繁忙')
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    const headers = response.headers
    if (headers.token) {
      window.Cookies.set('token', headers.token)
    }
    if (!res.errno) {
      return res
    }
    if (response.config.skipErrorToast) {
      // ignore
    } else if (res.code === 50002) {
      // store.dispatch('account/logout')
      window.location.replace('/login')
      showErrorMessage('您已被登出，请重新登录')
    } else {
      showErrorMessage(res.errmsg || '网络繁忙')
    }
    return Promise.reject(res)
  },
  error => {
    showErrorMessage(error.message || '网络繁忙')
    return Promise.reject(error)
  }
)

export default service
