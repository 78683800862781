<template>
  <div ref="chartRef" class="chart"></div>
</template>

<script>
import * as echarts from 'echarts/dist/echarts'
const elementResizeDetectorMaker = require('element-resize-detector')
export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      erd: null,
      myChart: null,
    }
  },
  watch: {
    options: {
      handler(newOption) {
        this.myChart.clear()
        this.myChart.setOption(newOption)
        this.myChart.resize()
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
    this.erd = elementResizeDetectorMaker()
    this.erd.listenTo(this.$refs.chartRef, () => {
      this.myChart.resize()
    })
  },
  beforeDestroy() {
    if (this.myChart) {
      // 卸载图例，防止内存泄露
      this.myChart.clear()
      this.myChart.dispose()
    }
    window.removeEventListener('resize', this.chartResize)
    this.erd.removeAllListeners(this.$refs.chartRef)
  },
  methods: {
    chartResize() {
      this.myChart.resize()
    },
    init() {
      this.myChart = echarts.init(this.$refs.chartRef)
      const obj = {}
      if (this.width) obj.width = this.width
      if (this.height) obj.height = this.height
      if (this.options && typeof this.options === 'object') {
        this.myChart.setOption(this.options, null, obj)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  /* width:500px; */
  /* height: 500px; */
}
</style>
