<template>
  <div class="login-container">
    <RegisterRight />
    <div class="login-wrapper-left">
      <div class="login-page">
        <LoginPageComponent ref="login" :btn-loading="btnLoading" @accountPasswordLoginSucceed="handleSubmit"></LoginPageComponent>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { fetchAccountInfo, fetchLoginAccount } from '@/api/account'
import { loadRoutes } from '@/utils/routerUtil'
import LoginPageComponent from './components/loginPageComponent.vue'
import RegisterRight from './components/registerRight.vue'
import Footer from '@/layouts/footer/index.vue'

export default {
  components: {
    LoginPageComponent,
    RegisterRight,
    Footer,
  },
  data() {
    return {
      showCurrentLoginStatus: 1, // 显示当前登录状态 1 登录页 2 手机验证 3立即注册 4 注册信息  5找回密码 6设置登录密码  7 选择集团 8选择品牌
      btnLoading: false, // 登录按钮加载状态
      loginMobile: '', // 手机号验证码 手机号
      groupList: [], // 集团列表
      brandList: [], // 品牌列表
      loginType: 2, // 1手机号登录 2账号密码登录 3 飞书登录
      callbackInterface: true, // 返回不调用获取验证码接口
      operationType: 'login', // 手机验证码 类型
      isCompatibleLixiansheng: false,
      compatibleLixianshengHostUrl: '',
    }
  },
  computed: {
    ...mapGetters('account', ['user']),
  },
  created() {
    // 存在登录态
    if (this.user) {
      this.$router.replace('/')
      return
    }
    this.setUser(null)
    this.setPermission(null)
    this.$store.commit('setMenuData', [])
  },
  methods: {
    ...mapMutations('account', [
      'setUser',
      'setPermission',
    ]),
    // 提交登录
    handleSubmit(loginData) {
      const { name, password, captchaId, captcha } = loginData
      const data = {
        name,
        password: this.$md5(password),
        captcha_id: captchaId,
        captcha_res: captcha,
      }
      this.btnLoading = true
      fetchLoginAccount(data)
        .then(async () => {
          await this.getUserInfo()
          this.btnLoading = false
          loadRoutes(() => {
            this.$router.push('/')
          })
        })
        .catch(() => {
          this.$refs.login.getCode()
          this.btnLoading = false
        })
    },
    getUserInfo() {
      return fetchAccountInfo().then(res => {
        this.setUser(res.data)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  background: #ffffff;
}
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-main-content {
  position: relative;
  box-sizing: border-box;
  width: 450px;
  height: 560px;
  padding: 20px 35px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.login-wrapper-left {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 635px;
  overflow: hidden;
  background: #ffffff;
  z-index: 100;
  .login-header {
    position: absolute;
    top: 30px;
    left: 50px;
    width: 172px;
    height: 36px;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
  }
  .login-page {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
.login-wrapper-right {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 520px;
  background-image: url(@/assets/svg/login-bg.svg);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: auto 100%;
}
.login-slogan {
  position: absolute;
  top: 22%;
  left: 50%;
  width: 374px;
  margin: 0 auto;
  color: #8993a9;
  font-weight: bold;
  font-size: 22px;
  line-height: 36px;
  letter-spacing: normal;
  text-align: left;
  transform: translateX(-50%);
}
.login-img {
  position: absolute;
  top: 37%;
  left: 50%;
  width: 520px;
  transform: translateX(-50%);
  img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 569px) and (min-width: 569px) and (max-width: 1024px) {
  .login-wrapper-right {
    display: none;
  }
}
@media (max-width: 568px) {
  .login-wrapper-right {
    display: none;
  }
}
</style>
