<template>
  <div class="order-container">
    <el-form :inline="true" size="small" class="search-box" :model="searchForm">
      <el-form-item label="关键词">
        <el-input
          v-model="searchForm.word"
          placeholder="请输入订单号/手机号/用户ID/会员码"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <div class="tabs">
      <div class="tab-wrap">
        <div
          v-for="status in orderStates"
          :key="status.key"
          class="tab-item"
          :class="{ active: orderState == status.key }"
          @click="tabChange(status.key)"
        >
          {{ status.desc }}
        </div>
      </div>
    </div>
    <el-table
      :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }"
      :data="list"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        prop="deal_id"
        label="订单编号"
        width="200"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="mobile"
        label="收件人手机号"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        label="会员码"
        prop="vipno"
      ></el-table-column>
       <el-table-column
        align="center"
        label="应收金额"
        prop="total_price"
      ></el-table-column>
       <el-table-column
        align="center"
        label="组合减免"
        prop="extra_minus"
      ></el-table-column>
      <el-table-column
        align="center"
        label="订单状态"
        prop="orderState"
      ></el-table-column>
     <el-table-column
        align="center"
        prop="create_time"
        label="下单时间"
        width="200"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | date }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="toDetail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="bottom">
      <el-pagination
        class="pagination"
        background
        layout="total, slot, prev, pager, next"
        :current-page="searchForm.pageNum"
        :page-size="searchForm.pageSize"
        :total="searchForm.total"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { fetchOrderList } from "@/api/order";
export default {
  name: "OrderList",
  data() {
    return {
      orderState: "ALL",
      orderStates: [
        {
          key: "ALL",
          desc: "全部",
        },
        {
          key: 5,
          desc: "待支付",
        },
        {
          key: 10,
          desc: "待发货",
        },
        {
          key: 20,
          desc: "待收货",
        },
        {
          key: 30,
          desc: "已收货",
        },
        {
          key: 40,
          desc: "待售后",
        },
        {
          key: 60,
          desc: "售后完成",
        },
        {
          key: 50,
          desc: "已完成",
        },
        {
          key: 0,
          desc: "已关闭",
        },
      ],
      isLoading: false,
      list: [],
      searchForm: {
        word: "",
        type: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  filters: {
    date(t) {
      return t ? new Date(t * 1000).toLocaleString() : "-";
    },
  },
  created() {
    this.getOrderList()
  },
  methods: {
    search() {
      this.searchForm.pageNum = 1;
      this.getOrderList();
    },
    clearSearch() {
      this.searchForm = {
        word: "",
        pageNum: 1,
        pageSize: 10,
        total: 0,
      };
      this.getOrderList();
    },
    handlePageChange(pageNum) {
      this.searchForm.pageNum = pageNum
      this.getOrderList()
    },
    getOrderList() {
      const data = { 
        index: this.searchForm.pageNum - 1, 
        size: this.searchForm.pageSize,
        word: this.searchForm.word,
     }
     if (this.orderState != 'ALL') {
      data.status = this.orderState
     }
      fetchOrderList(data).then(res => {
        const list = res.data.list || []
        list.forEach(item => {
          item.orderState = this.orderStates.find(i => i.key == item.status)?.desc || '-'
        })
        this.searchForm.total = res.data.total
        this.list = list
    });
    },
    // 订单状态变更
    tabChange(val) {
      this.searchForm.pageNum = 1
      this.orderState = val
      this.search()
    },
    toDetail(row) {
      this.$router.push(`/order/detail?deal_id=${row.deal_id}`)
    }
  }
};
</script>

<style lang="scss" scoped>
.order-container {
  background: #fff;
  padding: 30px;

  .search-box {
    margin-bottom: 20px;

    .el-form-item {
      margin-right: 20px;
    }

    .el-input {
      width: 260px;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .pagination ::v-deep {
      margin-top: 20px;
      text-align: right;
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  .tab-wrap {
    display: flex;
    overflow: hidden;
    border-radius: 4px;
    user-select: none;
  }
  .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 24px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    background: #ffffff;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    cursor: pointer;
  }
  .tab-item:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 4px 0px 0px 4px;
  }
  .tab-item:last-child {
    border-right: 1px solid #d9d9d9;
    border-radius: 0px 4px 4px 0px;
  }
  .tab-item.active {
    position: relative;
    color: #ffffff;
    background: #1fc069;
    border-color: #1fc069;
    &::after {
      position: absolute;
      top: -1px;
      right: -1px;
      bottom: -1px;
      width: 1px;
      background-color: #1fc069;
      content: "";
    }
  }
}
</style>
