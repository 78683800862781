<template>
  <div class="global-outside-div">
    <el-form :model="form" label-position="top" :rules="rules" class="form">
      <el-form-item label="用户名：">
        <div>
          <div class="read-input">
            <span>{{ form.staff_name }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="角色：">
        <div>
          <div class="read-input">
            <span>{{ form.role }}</span>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="手机号：" prop="mobile">
        <el-input
          v-model="form.mobile"
          placeholder="请输入手机号"
          class="item-input"
          :maxlength="11"
          :disabled="readPretty"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item v-if="readPretty">
        <el-button
          type="primary"
          @click.stop="readPretty = false"
          >修改</el-button
        ></el-form-item
      >
    </el-form>
    <div v-if="!readPretty" class="btn-group">
      <el-button type="default" @click="cancel">取消</el-button>
      <el-button type="primary" :loading="isLoading" :disabled="!btnStatus" @click="onSubmit"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Role } from "@/utils/role";
import { fetchAccountInfo, fetchUpdateAccountInfo } from '@/api/account'

const validatorPhone = (rule, value, callback) => {
  // 如果为空值，就抛出错误
  if (!value) {
    // callback(new Error('请输入有效的电话号码'))
  } else {
    // 正则判断手机号格式的格式，正则判断失败抛出错误，否则直接callback()
    let reg = !/^1\d{10}$/.test(value)
    if (reg) {
      callback(new Error('请输入有效的手机号'))
    } else {
      callback()
    }
  }
}

export default {
  data() {
    return {
      isLoading: false,
      btnStatus: false, // 按钮状态
      storeId: "", // 店铺id
      form: {
        staff_name: "", // 用户名
        staff_type: "", // 角色
        role: '', // 角色描述
        mobile: "", // 手机号
        password: "", // 密码
      },
      readPretty: true, // 只读模式
      rules: {
        mobile: [{ validator: validatorPhone, trigger: 'blur' }],
      },
    };
  },
   watch: {
    'form.mobile': function (n) {
      const phone = n && n.trim()
      if (phone) {
        this.form.mobile = this.form.mobile.replace(/\D/g, '')
        if (/^1\d{10}$/.test(phone)) {
          this.btnStatus = true
        } else {
          this.btnStatus = false
        }
      } else {
        this.btnStatus = false
      }
    },
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  created() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      fetchAccountInfo().then(res => {
        const { staff_name, staff_type, mobile } = res.data;
        this.form = {
          staff_name,
          role: Role[staff_type],
          staff_type,
          mobile,
        }
      })
    },
    // 取消
    cancel() {
      this.getUserInfo()
      this.readPretty = true
    },
    // 提交更新数据
    onSubmit() {
      if (!this.form.staff_name) {
        this.$message.error('请输入用户名')
        return false
      }
      if (!this.form.staff_type) {
        this.$message.error('请选择角色')
        return false
      }
      if (!this.form.mobile) {
        this.$message.error('请输入手机号')
        return false
      }
      this.isLoading = true
      // 提交表单
      this.saveUserInfo()
    },
     // 保存表单
    saveUserInfo() {
      const data = {
        mobile: this.form.mobile,
      }
      fetchUpdateAccountInfo(data)
        .then(() => {
          this.$message.success('更新成功')
          this.getUserInfo()
          this.readPretty = true
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.global-outside-div {
  .form {
    padding-bottom: 100px;
    ::v-deep .el-form-item {
      margin-bottom: 24px;
    }
    .item-input {
      width: 360px;
    }
    ::v-deep .el-input.is-disabled .el-input__inner {
      padding: 0 8px;
      color: #bbbbbb;
      background-color: #f8f8f8;
      border-color: #d9d9d9;
    }
    ::v-deep .el-input__inner {
      padding: 0 8px;
      color: var(--color, #333333);
    }
    .read-input {
      display: flex;
      align-items: center;
      width: 360px;
      height: 32px;
      padding: 0 8px;
      color: #bbbbbb;
      background-color: #f8f8f8;
      background-image: none;
      border: var(--input-border);
      border-color: #d9d9d9;
      border-radius: 4px;
      outline: 0;
      cursor: not-allowed;
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
    ::v-deep .el-form-item__label {
      padding: 0 0 4px;
      color: var(--color-1, #222222);
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
    ::v-deep .el-form-item--small .el-form-item__content {
      line-height: 20px;
    }
  }
  .btn-group {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    background: var(--bg-1, #fff);
    box-shadow: 0 0 10px 0 rgba(12, 4, 7, 0.1);
  }
  .el-button {
    min-width: 80px;
  }
}

html.dark {
  .content {
    .form {
      ::v-deep .el-input.is-disabled .el-input__inner,
      .read-input,
      .el-input__inner {
        background-color: var(--input-background-color);
        border-color: var(--line-1, #dcdfe6);
      }
    }
  }
}

::v-deep {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  //兼容火狐
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
