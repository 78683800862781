<template>
  <div class="setting-container">
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="tab in tabList"
        :key="tab.name"
        :label="tab.label"
        :name="tab.name"
      >
        <component :is="tab.component"></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PanelGradient from "./components/panelGradient/index.vue";
import BgGradient from "./components/bgGradient/index.vue";
import { fetchSettingConfigs } from "@/api/setting";
import HotBlock from "./components/hotBlock/index.vue";
import Support from "./components/support/index.vue";
import Env from "./components/env/index.vue";
import BonusApportion from "./components/bonusApportion/index.vue";
import Banner from "./components/banner/index.vue";

export default {
  name: "Setting",
  components: {},
  created() {
    this.getSetting();
  },
  data() {
    return {
      activeName: "",
      tabList: [],
      componnets: {
        panel_gradients: PanelGradient,
        bg_gradient: BgGradient,
        hot_block: HotBlock,
        mini_env: Env,
        support: Support,
        bonus_apportion: BonusApportion,
        banner: Banner,
      },
    };
  },
  methods: {
    getSetting() {
      fetchSettingConfigs().then((res) => {
        const data = res.data;
        let tabList = [];
        for (const key in data) {
          tabList.push({
            name: key,
            label: data[key].display_name,
            component: this.componnets[key],
          });
        }
        this.tabList = tabList;
        this.activeName = this.tabList[0].name;
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.setting-container {
  background: #fff;
  padding: 30px;
}
</style>
