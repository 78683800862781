import Vue from 'vue'
import VueRouter from 'vue-router'

import { defaultRouter } from './config'

const routes = defaultRouter

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
// 不需要登录拦截的路由配置
const loginIgnore = {
  names: ['404', '403', 'activation'], // 根据路由名称匹配
  paths: [
    '/login',
    '/updatePassword',
    '/forgetPassword',
    '/accountRegistration',
    '/agreement',
    '/privacyPolicy',
  ], // 根据路由path匹配
  /**
   * 判断路由是否包含在该配置中
   * @param route vue-router 的 route 对象
   * @returns {boolean}
   */
  includes(route) {
    return this.names.includes(route.name) || this.paths.includes(route.path)
  },
}

/**
 * 初始化路由实例
 * @param isAsync 是否异步路由模式
 * @returns {VueRouter}
 */
function initRouter() {
  return new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior: () => ({
      // 滚动条返回顶部
      y: 0,
      x: 0,
    }),
  })
}

export { initRouter, loginIgnore }
