import fetch from '@/utils/fetch'

/**
 * 查询用户统计
 */
export function fetchUserStatic(params) {
  return fetch({
    url: '/admin/statis/user',
    method: 'GET',
    params,
  })
}

/**
 * 查询订单统计
 */
export function fetchOrderStatic(params) {
  return fetch({
    url: '/admin/statis/order',
    method: 'GET',
    params,
  })
}
