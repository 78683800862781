<template>
  <div class="order-container">
    <div class="order-info">
      <div class="info-item">
        <div class="info-title">订单信息</div>
        <div class="info-content">
          <div class="info-text">订单编号：{{ info.deal_id }}</div>
          <div class="info-text">下单人：{{ info.mobile }}</div>
          <div class="info-text">下单时间：{{ info.createAt }}</div>
          <div class="info-text">应收金额：{{ info.total_price }}</div>
          <div class="info-text">组合减免：{{ info.extra_minus }}</div>
          <div class="info-text">收货地址：{{ info.address }}</div>
        </div>
      </div>
      <div v-if="info.extra && info.extra.refund_res" class="info-item">
        <div class="info-title">售后信息</div>
        <div class="info-content">
          <div class="info-text">退款编号：{{ info.extra.refund_res.refund_id }}</div>
          <div class="info-text">退款金额：{{  info.extra.refund_res.amount.refund }}</div>
          <div class="info-text">退款状态：{{ info.extra.refund_res.refund_status }}</div>
          <div class="info-text">退款时间：{{ info.extra.refund_res.success_time }}</div>
          <div class="info-text">售后时间：{{ info.extra.aftersale_res.time }}</div>
          <div class="info-text">售后原因：{{ info.extra.aftersale_res.summary }}</div>
        </div>
      </div>
    </div>
    <div class="section-title">商品信息</div>
    <el-table ref="dataTable" :header-cell-style="{
      background: 'var(--bg-7, #f5f5f5)',
      color: 'var(--color, #333333)',
    }" :data="goods" style="width: 100%" border @selection-change="handleSelectionChange">
      <el-table-column align="center" prop="goods_id" label="商品ID" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" label="商品名称" width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.extra.name }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="数量" prop="amount"></el-table-column>
      <el-table-column align="center" label="应收金额" prop="price">
      </el-table-column>
      <el-table-column align="center" label="组合减免" prop="minus"></el-table-column>
    </el-table>
    <template v-if="bonus_logs && bonus_logs.length">
       <div class="section-title">分账信息</div>
      <el-table ref="dataTable" :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }" :data="bonus_logs" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column align="center" prop="user_id" label="用户ID" min-width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="amountYuan" label="金额" min-width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="状态" prop="statusText" min-width="200"></el-table-column>
        <el-table-column align="center" label="openId" prop="openid" min-width="220">
        </el-table-column>
        <el-table-column align="center" label="明细单号" prop="billId" min-width="220"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" min-width="200"></el-table-column>
        <el-table-column align="center" label="完成时间" prop="finishTime" min-width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.finishTime || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="原因" prop="reason" min-width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.reason || '-' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="parcels && parcels.length">
      <div class="section-title">快递信息</div>
      <el-table ref="parcelsTable" :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }" :data="parcels" style="width: 100%" border :span-method="parcelsSpanMethod">
        <el-table-column align="center" prop="parcel_id" label="快递ID" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="express_name" label="快递公司" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="express_no" label="快递单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="商品" prop="name" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="保单号" width="200" prop="policy_no">
        </el-table-column>
        <el-table-column align="center" label="查询ID" min-width="200" prop="waybill_token"></el-table-column>
        <el-table-column align="center" min-width="150" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" min-width="150" label="更新时间" prop="updateTime"></el-table-column>
        <el-table-column  v-if="info.status == 10" label="操作" align="center" fixed="right" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="delParcel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <template v-if="aftersales && aftersales.length">
      <div class="section-title">售后信息</div>
      <el-table ref="afterTable" :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }" :data="aftersales" style="width: 100%" border :span-method="spanMethod">
        <el-table-column align="center" prop="after_id" label="售后ID" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="return_id" label="微信退货ID" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="退货商品" prop="name" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="退货数量" prop="amount" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="退货金额" prop="price">
        </el-table-column>
        <el-table-column align="center" label="快递公司" prop="express_com"></el-table-column>
        <el-table-column align="center" label="快递单号" prop="express_no"></el-table-column>
        <el-table-column align="center" label="快递状态" prop="desc"></el-table-column>
        <el-table-column align="center" min-width="150" label="更新时间" prop="updateTime"></el-table-column>
      </el-table>
      <!-- 订单操作 -->
      <div class="order-operat">
        <el-button v-if="info.status == 40" size="mini" @click="handleAfterSale">
            售后完成
        </el-button>
      </div>
    </template>
    <!-- 订单操作 -->
    <div class="order-operat">
      <el-button v-if="info.status == 10 || info.status == 20" size="mini" @click="addParcel">
        添加包裹
      </el-button>
      <el-button v-if="info.status == 30" size="mini" @click="addReturn">
        退货
      </el-button>
      <el-button v-if="info.status == 10" size="mini" @click="handleSend">
        发货
      </el-button>
      <el-button size="mini" @click="goBack">
        返回
      </el-button>
    </div>
    <!-- 发货弹窗 -->
    <DeliveryDialog ref="deliveryDialog" :is-show-delivery="showDelivery" @onConfirm="sendexpressOrder" @cancel="onCancel">
    </DeliveryDialog>
    <el-dialog title="选择商品" width="700px" :visible="isShowSelect" center :before-close="cancel">
      <el-table ref="selectTable" :header-cell-style="{
        background: 'var(--bg-7, #f5f5f5)',
        color: 'var(--color, #333333)',
      }" :data="goods" style="width: 100%" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="100" label align="center"></el-table-column>
        <el-table-column align="center" prop="goods_id" label="商品ID" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" label="商品名称" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.extra.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数量" prop="amount"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { fetchOrderDetail, fetchDeliver, fetchAfterSale, fetchAfterSaleReturn, fetchParcel } from "@/api/order";
import DeliveryDialog from './components/deliveryDialog.vue'

export default {
  name: "OrderDetail",
  data() {
    return {
      isLoading: false,
      deal_id: '',
      info: {},
      goods: [],
      aftersales: [],
      parcels: [],
      bonus_logs: [],
      showDelivery: false,
      goods_ids: [],
      selectType: 0,
      isShowSelect: false
    };
  },
  filters: {
    date(t) {
      return t ? new Date(t * 1000).toLocaleString() : "-";
    },
  },
  components: {
    DeliveryDialog,
  },
  activated() {
    this.deal_id = this.$route.query.deal_id || ''
    this.getOrderDetail()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    getOrderDetail() {
      const data = {
        deal_id: this.deal_id,
      }
      fetchOrderDetail(data).then(res => {
        res.data.createAt = new Date(res.data.create_time * 1000).toLocaleString()
        const {province, city, district, name} = res.data.extra.address
        let addressArr = [province, city, district, name].filter(i => !!i)
        res.data.address = addressArr.join('/')
        let aftersales = res.data.aftersales || []
        this.handleAfterSales(aftersales)
        let parcels = res.data.parcels || []
        this.handleParcels(parcels)
        let bonus_logs = res.data.bonus_logs || []
        this.handleBonus(bonus_logs)
        this.info = res.data
        this.goods = res.data.deal_goods
      });
    },
    handleAfterSales(aftersales) {
      let arr = []
      aftersales.forEach(item => {
        if (item.extra.goods_list && item.extra.goods_list.length) {
          item.extra.goods_list.forEach((goods, index) => {
            let _goods = {
              name: goods.name,
              amount: item.extra.goods[index].amount,
              after_id: item.after_id,
              return_id: item.return_id,
              price: item.order_price / 100,
              updateTime: new Date(item.update_time * 1000).toLocaleString(),
              express_com: item.express_com || '-',
              express_no: item.express_no || '-',
              desc: item.desc || '-',
            }
            if (index == 0) {
              _goods.rowspan = item.extra.goods_list.length
            } else {
              _goods.rowspan = 0
            }
            arr.push(_goods)
          })
        }
      })
      this.aftersales = arr
    },
    handleParcels(parcels) {
      let arr = []
      parcels.forEach(item => {
        let goodsList = item.extra.goods_info.detail_list || []
        if (goodsList.length) {
          goodsList.forEach((goods, index) => {
            let _goods = {
              name: goods.goods_name,
              parcel_id: item.parcel_id,
              express_name: item.express_com_name,
              express_no: item.express_no,
              policy_no: item.policy_no || '-',
              waybill_token: item.waybill_token || '-',
              createTime: new Date(item.create_time * 1000).toLocaleString(),
              updateTime: new Date(item.update_time * 1000).toLocaleString(),
            }
            if (index == 0) {
              _goods.rowspan = goodsList.length
            } else {
              _goods.rowspan = 0
            }
            arr.push(_goods)
          })
        }
      })
      this.parcels = arr
    },
    handleBonus(bonus_logs) {
      bonus_logs.forEach(bonus => {
        if (bonus.status == 0) {
          bonus.statusText = '待支付'
        }
        if (bonus.status == 10) {
          bonus.statusText = '已支付'
        }
        if (bonus.status == 20) {
          bonus.statusText = '支付失败'
        }
        bonus.amountYuan = bonus.amount / 100
        bonus.openid = bonus.extra.account
        bonus.billId = bonus.extra.detail_id
        bonus.createTime = new Date(bonus.create_time * 1000).toLocaleString()
        bonus.finishTime = new Date(bonus.finish_time * 1000).toLocaleString()
        bonus.reason = bonus.extra.fail_reason
      })
       this.bonus_logs = bonus_logs
    },
    handleSelectionChange(selection) {
      this.goods_ids = selection.map(item => {
        return {
          goods_id: item.goods_id,
          amount: item.amount,
        }
      })
    },
    handleSend() {
      this.$confirm(
        `是否确认已完成快递添加并立即发货？`,
        '提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        const data = {
          deal_id: this.deal_id
        }
        fetchDeliver(data).then(() => {
          this.$message.success('操作成功')
          this.getOrderDetail()
        })
      })
      .catch(() => {
        return false
      })
    },
    sendexpressOrder(opt) {
      const { express_com, express_no } = opt
      const data = {
        deal_id: this.deal_id,
        edit_type: 'add',
        express_com,
        express_no,
        goods_ids: this.goods_ids.map(item => item.goods_id),
      }
      fetchParcel(data).then(() => {
        this.goods_ids = []
        this.$message.success('操作成功')
        this.showDelivery = false;
        this.getOrderDetail()
      })
        .catch(() => {
          this.goods_ids = []
          this.showDelivery = false;
        });
    },
    delParcel(row) {
      this.$confirm(
        `确认要删除该包裹吗？`,
        '提示',
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).then(() => {
        const data = {
          parcel_id: row.parcel_id,
          deal_id: this.deal_id,
          edit_type: 'del'
        }
        fetchParcel(data).then(() => {
          this.$message.success('操作成功')
          this.getOrderDetail()
        })
      })
      .catch(() => {
        return false
      })
    },
    addReturn() {
      this.selectType = 2
      this.isShowSelect = true
    },
    handleReturn() {
      this.$confirm(
        `确认要退货吗？`,
        '提示',
        {
          type: 'warning',
        }
      ).then(() => {
         const data = {
          deal_id: this.deal_id,
          goods:  this.goods_ids,
        }
        fetchAfterSaleReturn(data).then(() => {
          this.goods_ids = []
          this.getOrderDetail()
          this.$message.success('操作成功')
        })
          .catch(() => {
            this.goods_ids = []
          });
        })
    },
    handleAfterSale() {
      this.$prompt('请输入售后原因、处理结果、客户满意度等', '售后总结', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator(value) {
          if (value) {
            return true
          }
          return '请输入售后原因、处理结果、客户满意度等'
        }
      }).then(({ value }) => {
        const data = {
          deal_id: this.deal_id,
          summary: value,
        }
        fetchAfterSale(data).then(() => {
          this.$message.success('操作成功')
        })
      }).catch(() => {})
    },
    onCancel() {
      this.selectType = 0
      this.showDelivery = false
      this.goods_ids = []
    },
    spanMethod({ row, columnIndex }) {
      if (columnIndex == 2 || columnIndex == 3) {
        return {
          rowspan: 1,
          colspan: 1,
        }
      }
      if (row.rowspan) {
        return {
          rowspan: row.rowspan,
          colspan: 1,
        }
      }
      return {
        rowspan: 0,
        colspan: 0,
      }
    },
    parcelsSpanMethod({ row, columnIndex }) {
      if (columnIndex == 3) {
        return {
          rowspan: 1,
          colspan: 1,
        }
      }
      if (row.rowspan) {
        return {
          rowspan: row.rowspan,
          colspan: 1,
        }
      }
      return {
        rowspan: 0,
        colspan: 0,
      }
    },
    addParcel() {
      this.selectType = 1
      this.isShowSelect = true
    },
    cancel() {
      this.selectType = 0
      this.isShowSelect = false
      this.goods_ids = []
    },
    confirm() {
      if (!this.goods_ids.length) {
        this.$message.error('请选择商品')
        return
      }
      this.isShowSelect = false
      if (this.selectType == 1) {
        this.showDelivery = true
        return
      }
      if (this.selectType == 2) {
        this.handleReturn()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.order-container {
  background: #fff;
  padding: 30px;

  .section-title {
    font-size: 22px;
    line-height: 40px;
    font-weight: 500;
    padding: 20px 0;
  }

  .order-info {
    position: relative;
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;

    .info-item {
      background: #f9fafc;
      padding: 12px;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .info-title {
      margin-bottom: 12px;
      font-size: 22px;
    }

    .info-content {
      display: flex;
      flex-wrap: wrap;

      .info-text {
        display: flex;
        align-items: center;
        width: 33.33%;
        padding-right: 10px;
        padding-bottom: 5px;
        color: #666;
        font-size: 14px;
        word-wrap: break-word;
      }
    }
  }

  .order-operat {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: 20px;

    .btn {
      width: 80px;
      height: 32px;
      margin-left: 10px;
      color: var(--color, #333333);
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      background: var(--bg-1, #ffffff);
      border: 1px solid #d2d2d2;
      border-radius: 4px;
    }
  }
}
</style>
