import { render, staticRenderFns } from "./editPicExtra.vue?vue&type=template&id=35f51f12&scoped=true"
import script from "./editPicExtra.vue?vue&type=script&lang=js"
export * from "./editPicExtra.vue?vue&type=script&lang=js"
import style0 from "./editPicExtra.vue?vue&type=style&index=0&id=35f51f12&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_vue-template-compiler@2.7.16_webpack@5.94.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f51f12",
  null
  
)

export default component.exports